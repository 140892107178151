/*=======================================
				Banner
=======================================*/

$sub-banner-bgcolor: #161f2a;
$sub-banner-background-small: url("/images/sub-banner.jpg");
$sub-banner-background: $sub-banner-background-small;

// Banner Heading
$sub-banner-h1-padding-small: rem-calc(40 15 20 15);
$sub-banner-h1-padding-medium: rem-calc(50 15 20 15);
$sub-banner-h1-padding-large: rem-calc(70 15 70 15);
$sub-banner-h1-text-color: #fff;
$sub-banner-h1-font-size: rem-calc(48);
$sub-banner-h1-font-weight: $global-weight-bold;
$sub-banner-h1-text-multiply: .8;
$sub-banner-h1-text-border: none;
$sub-banner-h1-border-spacing: rem-calc(10);

.sub-banner{
	// padding: $sub-banner-h1-padding-small;
	background-color: $sub-banner-bgcolor;
	background-image: $sub-banner-background-small;
	background-size: cover;
	background-position: 50% 0;
	background-repeat: no-repeat;
	border-bottom: 1px solid #000079;

	#sub-banner-container > * {
		@extend h1;
		display: inline-block;
		margin: $sub-banner-h1-padding-small;
		padding-bottom: $sub-banner-h1-border-spacing;
		position: relative;

		color: $sub-banner-h1-text-color;
		font-size: $sub-banner-h1-font-size * $sub-banner-h1-text-multiply;
		font-weight: $sub-banner-h1-font-weight;
		text-shadow: 0 2px 3px #000;

		&::after{
			display: block;
			width: 100%;
			height: 2px;
			position: absolute;
			bottom: 0;
			left: 0;
			content: "";

			background: linear-gradient(
				90deg,
				rgba(0,0,0,0) 0%,
				transparentize(#b5d0ea, 0) 30%,
				transparentize(#b5d0ea, 0) 70%,
				rgba(0,0,0,0) 100%
			);
		}
	}

	@include breakpoint(medium){
		background-image: $sub-banner-background;

		#sub-banner-container > * {
			margin: $sub-banner-h1-padding-medium;
			color: $sub-banner-h1-text-color;
			font-size: $sub-banner-h1-font-size;
		}
	}

	@include breakpoint(large){
		// min-height:rem-calc(150);

		#sub-banner-container > * {
			margin: $sub-banner-h1-padding-large;
		}
	}

	@media (min-width: 1260px){
		#sub-banner-container > * {
			margin-left: 0;
			margin-right: 0;
		}

	}
}
