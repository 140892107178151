@charset "UTF-8";

@import "partials/foundation-settings";

/*=======================================
        Imports
=======================================*/

// Normalize 3.0.3
@import "vendor/normalize/normalize";

/**
 * Foundation for Sites by ZURB
 * Version 6.2.3
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */

// Sass utilities
@import 'vendor/foundation/util/util';

// Global variables and styles
@import 'vendor/foundation/global';

// Components
@import 'vendor/foundation/grid/grid';
@import 'vendor/foundation/typography/typography';
@import 'vendor/foundation/forms/forms';
@import 'vendor/foundation/components/visibility';
@import 'vendor/foundation/components/float';
@import 'vendor/foundation/components/button';
@import 'vendor/foundation/components/button-group';
// @import 'vendor/foundation/components/accordion-menu';
@import 'vendor/foundation/components/accordion';
// @import 'vendor/foundation/components/badge';
// @import 'vendor/foundation/components/breadcrumbs';
@import 'vendor/foundation/components/callout';
@import 'vendor/foundation/components/close-button';
@import 'vendor/foundation/components/drilldown';
@import 'vendor/foundation/components/dropdown-menu';
@import 'vendor/foundation/components/dropdown';
@import 'vendor/foundation/components/flex';
@import 'vendor/foundation/components/flex-video';
@import 'vendor/foundation/components/label';
// @import 'vendor/foundation/components/media-object';
@import 'vendor/foundation/components/menu';
@import 'vendor/foundation/components/menu-icon';
// @import 'vendor/foundation/components/off-canvas';
// @import 'vendor/foundation/components/orbit';
// @import 'vendor/foundation/components/pagination';
// @import 'vendor/foundation/components/progress-bar';
// @import 'vendor/foundation/components/reveal';
// @import 'vendor/foundation/components/slider';
@import 'vendor/foundation/components/sticky';
// @import 'vendor/foundation/components/switch';
@import 'vendor/foundation/components/table';
// @import 'vendor/foundation/components/tabs';
@import 'vendor/foundation/components/title-bar';
@import 'vendor/foundation/components/top-bar';
@import 'vendor/foundation/components/thumbnail';
// @import 'vendor/foundation/components/tooltip';

@mixin foundation-everything($flex: true) {
  @if $flex {
    $global-flexbox: true !global;
  }

  @include foundation-global-styles;
  @if not $flex {
    @include foundation-grid;
  }
  @else {
    @include foundation-flex-grid;
  }
  @include foundation-typography;
  @include foundation-forms;
  @include foundation-button;
  @include foundation-accordion;
  // @include foundation-accordion-menu;
  // @include foundation-badge;
  // @include foundation-breadcrumbs;
  @include foundation-button-group;
  @include foundation-callout;
  @include foundation-close-button;
  @include foundation-menu;
  @include foundation-menu-icon;
  @include foundation-drilldown-menu;
  @include foundation-dropdown;
  @include foundation-dropdown-menu;
  @include foundation-flex-video;
  @include foundation-label;
  // @include foundation-media-object;
  // @include foundation-off-canvas;
  // @include foundation-orbit;
  // @include foundation-pagination;
  // @include foundation-progress-bar;
  // @include foundation-slider;
  @include foundation-sticky;
  // @include foundation-reveal;
  // @include foundation-switch;
  @include foundation-table;
  // @include foundation-tabs;
  @include foundation-thumbnail;
  @include foundation-title-bar;
  // @include foundation-tooltip;
  @include foundation-top-bar;
  @include foundation-visibility-classes;
  @include foundation-float-classes;

  @if $flex {
    @include foundation-flex-classes;
  }
}

@include foundation-everything(true);

@import "partials/fonts";

// Flexslider 2.4.0
@import "vendor/flexslider/flexslider";

// Fancybox 2.1.5
@import "vendor/fancybox/jquery.fancybox";

// ImageLightBox by http://osvaldas.info/image-lightbox-responsive-touch-friendly
@import "vendor/imagelightbox/imagelightbox";

// Architecture Settings
@import "partials/hlm-mixins";
@import "partials/hlm-functions";

// HLM Base Styles
@import "partials/base";
@import "partials/articles";


/*=======================================
          HLM Modules
=======================================*/
// Header Options
// @import "modules/header/header.nav-contain";
// @import "modules/header/header.nav-top";
@import "modules/header/header.nav-under";

// Banner Options
// @import "modules/banner/banner.callout-column";
// @import "modules/banner/banner.form";
@import "modules/banner/banner.full-width";
// @import "modules/banner/banner.sliding-gallery-banners";

// Callout Options
@import "modules/callouts/callout.bar";
// @import "modules/callouts/callout.content";
@import "modules/callouts/callout.footer";
@import "modules/callouts/callouts.adams";
@import "modules/callouts/callouts.bagley";
@import "modules/callouts/callouts.cass";

// Gallery Options
@import "modules/gallery/gallery.ImageLightbox";

// Testimonial Options
// @import "modules/testimonials/testimonial.single";
@import "modules/testimonials/testimonials.slider";

// Social Link Options
@import "modules/social/social.round-links";
@import "modules/social/social.vertical-bar";

// Footer Options
// @import "modules/footer/footer.quarter-half-quarter";
@import "modules/footer/footer.stacked";

// HLM Sections
@import "sections/header";
@import "sections/layouts";
@import "sections/footer";
@import "sections/page-home";
@import "sections/sub-banner";

// HLM Other
@import "partials/coupons";
@import "partials/events";
@import "partials/blog-grid";
@import "partials/events";
@import "partials/top-bar";
@import "partials/form-controls";
@import "partials/subnav";
@import "partials/offcanvas-hlm";
@import "partials/sml";

/*=======================================
      Custom Site Styles
=======================================*/

.content-text{
	strong{
		color: #2953b0;
	}

	.callout-maintenance{
		padding: rem-calc(20 22);
		color: #fff;
		background: #ff1414 linear-gradient(#ff1414, #c60909);
		border: 1px solid #f00c0c;

		.heading{
			margin-bottom: rem-calc(16);
			color: #ffe400;
			font-size: rem-calc(28);
			font-weight: 600;
		}

		p{
			line-height: 1.3;
		}

		a{
			color: #ffe400;
		}

		strong{
			color: #fff;
		}
	}
}

.callout-content{
	color: #2953b0;
	font-size: rem-calc(16);
	font-weight: 600;
	line-height: 1.5;
	
	a{
		color: #ca0a0a;
	}
}